<template>
    <modal ref="modalMoverUnidades" titulo="Mover unidades de producto" @guardar="gestionarInventario">
        <ValidationObserver ref="form">
            <div class="row mx-0 px-4">
                <div class="col-12">
                    <label class="f-14 text-general pl-3">Seleccionar producto</label>
                    <ValidationProvider v-slot="{errors}" rules="required" name="producto" class="d-flex flex-column">
                        <el-select
                        v-model="model.id_producto"
                        filterable
                        remote
                        reserve-keyword
                        placeholder="Buscar producto"
                        :remote-method="remoteMethod"
                        class="w-100"
                        @change="setProducto"
                        >
                            <el-option
                            v-for="item in productos"
                            :key="item.id"
                            :label="`${item.nombre} - ${item.presentacion}`"
                            :value="item.id"
                            />
                        </el-select>
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div v-if="!_.isEmpty(producto)" class="col-12 mt-3 d-flex">
                    <img :src="producto.imagen" class="obj-cover br-8 border" width="100px" height="100px" />
                    <div class="col">
                        <p class="text-general nombre">
                            {{ producto.nombre }}
                        </p>
                        <p class="f-13 text-general2">{{ producto.presentacion }}</p>
                        <p class="f-14 text-general f-600"> {{ separadorNumero(producto.valor) }} Valor actual</p>
                        <p class="f-14 text-general f-600">{{ producto.stock_actual ? producto.stock_actual : 0 }} Stock actual</p>
                    </div>
                </div>
                <div class="col-12 py-4" />
                <div class="col-12">
                    <div class="row mx-0">
                        <div class="col">
                            <div class="row mx-0 text-general">
                                <input v-model="model.tipo" :value="2" type="radio" class="option-input black radio" />
                                Ingresar
                            </div>
                            <div class="row mx-0 text-general mt-3">
                                <input v-model="model.tipo" :value="1" type="radio" class="option-input black radio" />
                                Retirar
                            </div>
                        </div>
                        <div class="col">
                            <p class="f-16 text-center  mx-0 text-general">Unidades</p>
                            <div class="row mx-0 justify-center">
                                <ValidationProvider v-slot="{errors}" rules="required|min_value:1" name="cantidad" class="d-flex flex-column">
                                    <el-input-number v-model="model.cantidad" :max="65000" size="small" style="width:145px;" />
                                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 py-4" />
                <div class="col-12">
                    <label class="f-14 text-general pl-3">Observacón del movimiento</label>
                    <ValidationProvider v-slot="{errors}" rules="max:250" name="justificacion" class="d-flex flex-column">
                        <el-input v-model="model.justificacion" type="textarea" :rows="4" placeholder="Observacion..." />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Inventarios from "~/services/inventarios/lecheros"
export default {
    props:{
        idLechero: {
            type: [String,Number],
            default:''
        }
    },
    data(){
        return {

            productos:[],
            model:{
                id_producto:null,
                cantidad:0,
                tipo:2,
                justificacion:''
            },
            producto:{}
        }
    },
    methods: {
        toggle(){
            this.$refs.modalMoverUnidades.toggle();
        },
        async gestionarInventario(){
            try {

                const valid = await this.$refs.form.validate()

                if(!valid) return

                const {data} = await Inventarios.gestionarInventario(this.idLechero, this.model)
                this.notificacion('Exito','Inventario actualizado correctamente','success')
                this.$refs.modalMoverUnidades.toggle();

            } catch (e){
                this.error_catch(e)
            }
        },
        async remoteMethod(query){
            try {
                if(query.length > 3){

                    let params = { query }
                    const {data} = await Inventarios.buscarProductos(params, this.idLechero)
                    this.productos = data.productos
                }

            } catch (e){
                this.error_catch(e)
            }
        },
        setProducto(id){
            const producto = this.productos.find(o=>o.id === id)
            this.producto = producto
        },

    }
}
</script>
